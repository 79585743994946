import { BackdropLoader, Drawer, PrivateRoute, Role, isAuthorised, useAuthorisation } from '@indigo-cloud/common-react';
import { DrawerNavigationList as SuotaUIDrawerNavigationList } from '@indigo-cloud/suota-ui';
import { DrawerNavigationList as USPControllerDrawerNavigationList } from '@indigo-cloud/usp-controller-ui';
import { DrawerNavigationList as AdminDrawerNavigationList } from '@indigo-cloud/admin-ui'

import React from 'react';
import Loadable from 'react-loadable';
import { Route, Switch, useLocation } from 'react-router-dom';

import styles from './App.router.module.scss';

import { useDispatch, useSelector } from 'react-redux';
import { theme } from './App.theme';
import awsExports from './aws-exports';
import { menuActions } from './store/actions';
import { menuSelectors } from './store/selectors';

export enum AppRoutes {
	Portal = '/',
	Portal401='401',
	NotFound = 'NotFound',
}

export type AppRouteKey = keyof typeof AppRoutes;
export const appRouteNames: AppRouteKey[] = [];

for (const appRouteKey in AppRoutes) {
	appRouteNames.push(appRouteKey as AppRouteKey);
}

const Loading = () => <BackdropLoader isLoading />;
export const pages = {
	[AppRoutes.Portal]: Loadable({
		loader: () => import('./pages/Portal/Portal.page'),
		loading: Loading
	}),
	[AppRoutes.NotFound]: Loadable({
		loader: () => import('./pages/NotFound/NotFound.page'),
		loading: Loading
	})
};

export const DrawerRouteNames = {
	Admin: AdminDrawerNavigationList,
	Suota: SuotaUIDrawerNavigationList,
	UspController: USPControllerDrawerNavigationList
}

export const drawerItems = [
	{
		Name: DrawerRouteNames.Suota,
		roles: [Role.ReadOnly, Role.Suota, Role.Firmware, Role.Admin]
	},
	{
		Name: DrawerRouteNames.UspController,
		roles: [Role.ReadOnly, Role.Device, Role.DeviceDevelopmentDevices, Role.DeviceDslTestLab, Role.DeviceTrialist, Role.Admin, Role.Firmware, Role.Report, Role.DeviceGroup, Role.DeviceGroupAdmin, Role.DeviceMaudResearchTrials]
	},
	{
		Name: DrawerRouteNames.Admin,
		roles: [Role.Admin]
	}
];


interface AppRouterProperties { }

export const AppRouter: React.FC<AppRouterProperties> = () => {

	const isOpen = useSelector(menuSelectors.isOpen);
	const dispatch = useDispatch();
	const location = useLocation();
	const userAuthorisationGroups = useAuthorisation();

	const onOpenChanged = (isOpen: boolean) => {
		dispatch(menuActions.toggleMenu(isOpen));
	}

	const onCloseDrawer = () => {
		dispatch(menuActions.toggleMenu(false));
	}


	return (
		<div className={styles.container}>
			<Drawer isOpen={isOpen} onOpenChanged={onOpenChanged} theme={theme}>
				{drawerItems
					.filter(({ roles }) => isAuthorised(roles, userAuthorisationGroups))
					.map(({ Name }) => {
						return <Name isOpen={isOpen} onCloseDrawer={onCloseDrawer} key={`key_${Name}`} />;

					})
				}
			</Drawer>
			<Switch location={location}>
				<Route path="/404" component={pages[AppRoutes.NotFound]} />
				{/* Private routes */}
				<PrivateRoute roles={[Role.ReadOnly, Role.Admin, Role.Device, Role.DeviceDevelopmentDevices, Role.DeviceDslTestLab, Role.DeviceTrialist, Role.DeviceGroup, Role.DeviceGroupAdmin, Role.Firmware, Role.Report, Role.Suota]} component={pages[AppRoutes.Portal]} awsExports={awsExports} />
			</Switch>
		</div>
	);
};

AppRouter.propTypes = {};
