import { Device, Thing } from '../types';
import { History } from 'history';
import { appRoutes } from '../../components';

export const getDeviceIdentifier = ({ agentEndpointId }: Device) => agentEndpointId;

export const getDeviceIdentifierUrl = (device: Device) => {
	const identifier = getDeviceIdentifier(device);
	return appRoutes.DeviceInfo.replace(':agentEndpointId', encodeURIComponent(identifier));
};

export const navigateToDeviceIdentifier = (device: Device, history: History<unknown>) => {
	const identifierUrl = getDeviceIdentifierUrl(device);
	history.push(identifierUrl);
}

export const getDeviceIdentifierUrlByThingName = (thingName: string) => {
	const agentEndpointId = `os::012345-${thingName}`;
	return appRoutes.DeviceInfo.replace(':agentEndpointId', encodeURIComponent(agentEndpointId));
};

export const navigateToDeviceIdentifierByThingName  = (thingName: string, history: History<unknown>) => {
	const identifierUrl = getDeviceIdentifierUrlByThingName(thingName);
	history.push(identifierUrl);
}

export const canActivateSpeedTest = (device: Device) => {
	return true;
	return !!(device?.agentEndpointId && device?.status?.toLowerCase() === 'online' && device?.externalId);
}

export const getDeviceAgentEndpointId = (serial: string) => {
	const prefix = "os::012345-";
	return `${prefix}${serial}`;
}
